<template>
  <div>
    <div v-if="groups.length > 0" class=" mb-3">
      Wil je twee groepen op één pagina printen?
      <div class="form-check">
        <input class="form-check-input" type="radio" v-model="extra_group_id" name="print_job[options][extra_group_id]" id="no_extra_group" value="false" checked>
        <label class="form-check-label" for="no_extra_group">
          Nee, print alleen voor {{ group.name_with_group }}
        </label>
      </div>
      <div :key="g.id" class="form-check" v-for="g in groups">
        <input class="form-check-input" type="radio" v-model="extra_group_id" name="print_job[options][extra_group_id]" :id="`extra_group_${g.id}`" :value="g.id">
        <label class="form-check-label" :for="`extra_group_${g.id}`">
          Ja, print {{group.name_with_group }} en {{g.name_with_group}} op één pagina
        </label>
      </div>  
    </div>

    
    <div v-show="extra_group_id == 'false'">
      <div class="form-check">

        <input class="form-check-input" type="radio" v-model="page_per_day" name="print_job[options][new_page_per_day]" id="new_per_day_true" value="true" checked>
        <label class="form-check-label" for="new_per_day_true">
          Print iedere dag op een nieuwe pagina
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" v-model="page_per_day" name="print_job[options][new_page_per_day]" id="new_per_day_false" value="false">
        <label class="form-check-label" for="new_per_day_false">
          Print de dagen direct na elkaar
        </label>
      </div>  
    </div>
    <div >
      <div class="form-group mt-3">
        <label for="show_extra_lines">Print extra lijnen bij iedere dag</label>
        <select class="form-control" v-model="extra_lines" name="print_job[options][show_extra_lines]" id="show_extra_lines">
         
          <option v-show="page_per_day == 'true'" value="1">Iedere pagina opvullen met lijnen</option>
          <option v-show="page_per_day == 'true'" value="100">Extra notitie-pagina toevoegen na iedere dag</option>
          <option value="0">Toon geen extra lijnen</option>
          <option v-show="page_per_day == 'false'" value="2">Toon 2 extra lijnen</option>
          <option v-show="page_per_day == 'false'" value="3">Toon 3 extra lijnen</option>
          <option v-show="page_per_day == 'false'" value="4">Toon 4 extra lijnen</option>
        </select>
      </div>
    </div>


    <div class="form-group" v-if="extra_lines > 0 && page_per_day">
        <label for="notes_font">Lettertype van opmerkingen</label>
        <select class="form-control" name="print_job[options][notes_font]" id="notes_font">
          <option value="handwritten">Handgeschreven</option>
          <option value="normal">Normaal lettertype</option>
          <!-- <option value="default-3">Achterkant met smiley-feedback en 3 velden</option> -->
        </select>
      </div>
    

    
      <div class="form-group" v-if="has_planned_notes && extra_group_id == 'false'">
        <label for="planned_notes_position">Zorgafspraken</label>
        <select class="form-control" name="print_job[options][planned_notes_position]" id="planned_notes_position">
          <option value="direct">Direct chronologisch in het rooster</option>
          <option value="bundled">Onderaan elke dagplanning</option>
          <!-- <option value="default-3">Achterkant met smiley-feedback en 3 velden</option> -->
        </select>
      </div>
    

    <div class="form-group mt-3" v-if="extra_group_id == 'false'">
      <label>Rekening houden met perforatiegaatjes?</label>
      <div class="form-check">

        <input class="form-check-input" type="radio" v-model="extra_space" name="print_job[options][space_for_holes]" id="space_for_holes_no" value="no" checked>
        <label class="form-check-label" for="space_for_holes_no">
          Nee, gebruik de maximale breedte van het papier
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" v-model="extra_space" name="print_job[options][space_for_holes]" id="space_for_holes_yes" value="yes">
        <label class="form-check-label" for="space_for_holes_yes">
          Ja, houd wat ruimte aan de linkerkant
        </label>
      </div>  
    </div>

    <div  class="form-group mt-3">
      <label>Doelen</label>
      <div class="form-check">
        <input class="form-check-input" type="radio" v-model="print_goals" name="print_job[options][print_goals]" id="yes_goals_print" value="true" checked>
        <label class="form-check-label" for="yes_goals_print">
          Print de lesdoelen direct bij de les
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" v-model="print_goals" name="print_job[options][print_goals]" id="no_goals_print" value="false">
        <label class="form-check-label" for="no_goals_print">
          Nee, druk de lesdoelen niet af
        </label>
      </div>  
    </div>



  </div>
</template>

<script>
export default {

  name: 'printOptionsForWeekPlan',
  props: ['groups', 'group', 'has_planned_notes'],
  data () {
    return {
      show_extra_lines: 'true',
      page_per_day: 'true',
      extra_group_id: 'false',
      print_goals: 'true',
      extra_space: 'yes',
      extra_lines: '1'
    }
  },
  watch: {
    page_per_day: {
      handler: function(oldVal, newVal) {
        this.perDayChanged()
      }
    }
  },
  methods: {
    perDayChanged() {
      console.log('changed')
      console.log(this.page_per_day)
      if (this.page_per_day == 'true') {
        console.log('fill with extra lines, option 1')
        this.extra_lines = '1'
      } else {
        console.log('no extra lines, option 0')
        this.extra_lines = '0'
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>