<template>
  <div>
    <div v-if="!loading">
      <div class="form-group">
        <label>Schoolnaam</label>
        <input type="text" class="form-control" v-model="schoolName" autofocus>
      </div>
      <div class="form-group">
        <label>Contactpersoon</label>
        <input type="text" class="form-control" v-model="contactPerson">
      </div>
      <div class="form-group">
        <label>Contactpersoon Telefoon (Datalek)</label>
        <input type="text" class="form-control" v-model="phone">
      </div>
      <div class="form-group">
        <label>Contactpersoon E-mail (Datalek)</label>
        <input type="text" class="form-control" v-model="email">
      </div>
      <div class="form-group">
        <label>Naam bestuurder</label>
        <input type="text" class="form-control" v-model="name">
      </div>
      <div class="form-group">
        <label>Straatnaam en huisnummer</label>
        <input type="text" class="form-control" v-model="address">
      </div>
      <div class="form-group">
        <label>Plaatsnaam</label>
        <input type="text" class="form-control" v-model="city">
      </div>
      <button @click="makeForm" class="btn btn-primary">Maak overeenkomst</button>
    </div>
    <div v-if="loading" class="text-center py-5">
      AVG verklaring wordt gemaakt, uitgeprint, ondertekend en weer ingescand door elfjes.<br>
      <i class="fa fa-spinner fa-spin fa-x3"></i>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {

  name: 'avgForm',

  data () {
    return {
      schoolName: '',
      name: '',
      phone: '',
      email: '',
      contactPerson: '',
      address: '',
      city: '',
      loading: false
    }
  },
  methods: {
    makeForm() {
      this.loading = true
      return axios.post("/elfjes-avg.json", {
        name: this.name, 
        school_name: this.schoolName, 
        contact_person: this.contactPerson, 
        phone: this.phone,
        email: this.email,
        address: this.address, 
        city: this.city
      }, {
        responseType: 'blob' // important
      })
      .then(response => {
        console.log(response)
        this.loading = false
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        // const filename = response.data.headers["content-disposition"].split('file-name="')[1].split('"')[0]
        link.setAttribute('download', `AVG_Scan_HPWP1800-${this.schoolName}(1).pdf`)
        document.body.appendChild(link)
        link.click()
      })
      .catch(error => {
        alert(error)
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>