
// seed data
import seedDataWeekPlan from '../seed/weekplan.json'

import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'
import Weektaak from '../packs/weektaak'



Vue.use(require('vue-moment'))
Vue.use(Vuex)

export default new Vuex.Store({

  state: { // data
    importJob: null,
    unreadChats: undefined,
    weekPlan: {id: null, group_courses: [], weekPlanDays: [{name: ''},{name: ''},{name: ''},{name: ''},{name: ''}]},
    weekPlanPerCourse: {courses: [], loadingLessonIds: []},
    courses: [],
    extraTasks: [],
    levels: [],
    book: {book_blocks: []},
    printJobs: [],
    students: [],
    showWeekPlanTrash: false,
    draggingNewLesson: false,
    weekPlanCourses: [],
    loading: true,
    modalLessonId: null,
    groupsToShow: [],
    loadingLessons: [],
    studentWeekPlans: [],
    schoolCourses: [],
    digitalWeekPlanMessages: [],
    previousDigitalWeekPlanMessages: [],
    digitalWeekPlan: {},
    pictoPlan: {},
    throwConfetti: false,
    weekPlanSettings: {},
    chats: [],
    user_chat: {}
  },

  getters: { // computed properties
    unreadChats (state) {
      return state.unreadChats
    },
    user_chat (state) {
      return state.user_chat
    },
    chats (state) {
      return state.chats
    },
    weekPlanSettings (state){
      return state.weekPlanSettings
    },
    studentWeekPlans (state){
      return state.studentWeekPlans
    },
    loadingLessons (state){
      return state.loadingLessons
    },
    throwConfetti (state){
      return state.throwConfetti
    },
    pictoPlan (state){
      return state.pictoPlan
    },
    digitalWeekPlan (state){
      return state.digitalWeekPlan
    },
    digitalWeekPlanMessages (state){
      return state.digitalWeekPlanMessages
    },
    previousDigitalWeekPlanMessages (state){
      return state.previousDigitalWeekPlanMessages
    },
    importJob (state){
      return state.importJob
    },
    currentModalLessonId (state){
      return state.modalLessonId
    },
    book (state){
      return state.book
    },
    isLoading (state) {
      return state.courses.length == 0
    },
    allLessons (state, getters) {
      var list = []
      getters.weekPlanDays.forEach((weekPlanDay) => {
        if (weekPlanDay.lessons != undefined) {
          // console.log(weekPlanDay)
          weekPlanDay.lessons.forEach((lesson) => {
            list.push(lesson)
          })          
        }
      })
      return list
    },
    totalCourseDurationFor: (state, getters)  => (course) => {
      // return state.weekPlan.weekPlanDays
      // console.log(getters.allLessons)
      // var startNum = state.weekPlan.weekPlanDays[0].starts_at
      return getters.allLessons
              .filter(lesson => lesson.course_id === course.id)
              .map(lesson => lesson.duration)
              .reduce((a, b) => a + b, 0)
    },
    showWeekPlanTrash (state) {
      return state.showWeekPlanTrash
    },
    draggingNewLesson (state) {
      return state.draggingNewLesson
    },
    weekPlanDays (state, getters) {
      return state.weekPlan.weekPlanDays
    },
    printJobs (state, getters) {
      return state.printJobs
    },
    groupsToShow (state, getters) {
      return state.groupsToShow
    },
    weekPlanPerCourse (state, getters){
      return state.weekPlanPerCourse
    },
    weekPlan (state, getters) {
      return state.weekPlan
    },
    // EXAMPLE:
    // getTodoById: (state) => (id) => {
    //   return state.todos.find(todo => todo.id === id)
    // },
    lessonsForDay: (state) => (dayName) => {
      return state.weekPlan.weekPlanDays.find(weekPlanDay => weekPlanDay.day === dayName).lessons
    },
    courses (state) {
      return state.courses
    },
    allCourses (state){
      return state.schoolCourses
    },
    extraTasks (state) {
      return state.extraTasks
    },
    levels (state) {
      return state.levels
    },
    students (state) {
      return state.students
    },
    coursesWithLevels (state) {
      if (state.courses != undefined){
        // console.log(state.courses)
        return state.courses.filter(course => course.isGroupCourse === true)
      } else {
        return []  
      }
      
    },
    weekPlanCourses (state) {
      return state.weekPlanCourses
    }
  },

  actions: {
    deleteLessonsFromWeekPlanDay(context, weekPlanDayId) {
      console.log('removing weekplan day')
      // Weektaak.getWeekPlanCourses(id.id)
      Weektaak.clearWeekPlanDay(weekPlanDayId)
        .then(apiResult => {
          context.commit("setWeekPlanDays", apiResult)
        })
        .catch(error => console.log(error))

    },
    copyWeekPlanDay(context, options) {
      // console.log('copying weekplan day')
      // console.log(options)
      // Weektaak.getWeekPlanCourses(id.id)
      Weektaak.copyWeekPlanDay(options)
        .then(apiResult => {
          context.commit("setWeekPlanDays", apiResult)
        })
        .catch(error => console.log(error))

    },
    fetchChatMessagesForUserChat(context, options) {
      // ', {uuid: this.uuid, user_chat_id: this.chat.id})
      if (options.user_chat_id) {
        Weektaak.getChatMessages(options.uuid, options.user_chat_id).then(apiResult => {
          context.commit("setUserChat", apiResult)
        }).catch(error => console.log(error))
      } else {
        Weektaak.getChatMessagesForStudent(options.uuid, options.student_id).then(apiResult => {
          context.commit("setUserChat", apiResult)
        }).catch(error => console.log(error))
      }
    },

    fetchChatMessages(context, uuid){
      Weektaak.getChats(uuid).then(apiResult => {
        context.commit("setChats", apiResult)
      }).catch(error => console.log(error))
    },
    setWeekPlanSettings(context, object){
      context.commit("setWeekPlanSettings", object)
    },
    addToLoadingLessons(context, lessonId){
      // console.log("adding to loading lessons")
      // console.log(lessonId)
    },
    removeFromLoadingLessons(context, lessonId){

    },
    setDigitalWeekPlanCredit(context, payload){
      // console.log("saving new score!")
      // console.log(payload)
      context.commit("setDigitalWeekPlanCredit", payload)
    },
    addDigitalWeekPlanMessage(context, message){
      context.commit("addDigitalWeekPlanMessage", message)
    },
    setDigitalWeekPlanMessages(context, weekPlanMessages){
      context.commit("setDigitalWeekPlanMessages", weekPlanMessages)
    },
    setPreviousDigitalWeekPlanMessages(context, weekPlanMessages){
      context.commit("setPreviousDigitalWeekPlanMessages", weekPlanMessages)
    },
    setDigitalWeekPlan(context, weekPlan){
      context.commit("setDigitalWeekPlan", weekPlan)
    },
    setGroupsToShow(context, groupsToShow){
      context.commit("saveGroupsToShow", groupsToShow)
    },
    copyExtraTask(context, options){
      // console.log(`copy task ${options.id} of week plan ${options.week_plan_id} to group ${options.group_id}`)
      Weektaak.copyExtraTask(options.week_plan_id, options.id, options.group_id).then(apiResult => {
        context.commit("setExtraTasks", apiResult)
      }).catch(error => console.log(error))
    },
    deleteExtraTask(context, task){
      // console.log(task.week_plan_id)
      // console.log(task.id)
      Weektaak.deleteExtraTask(task.week_plan_id, task.id).then(apiResult => {
        context.commit("setExtraTasks", apiResult)
      }).catch(error => console.log(error))
    },
    updateStudentLevel(context, options) {
      // ', {studentLevelId: this.studentLevelId, levelId: this.selectedLevel})
      Weektaak.updateStudentLevel(options).then(apiResult => {
        context.commit('setStudents', apiResult)
        // console.log(apiResult)
      }).catch(error => console.log(error))
    },
    updateExtraTaskLevel(context, options) {
      Weektaak.updateExtraTaskLevel(options).then(apiResult => {
        context.commit("setExtraTasks", apiResult)
      }).catch(error => console.log(error))
    },
    updateGroup(context, options){
      Weektaak.updateGroup(options)
        .then(apiResult => {
          // console.log('updated...')
          // console.log(apiResult)
          context.commit('setCourses', apiResult.setCourses)
          context.commit('setStudents', apiResult.setStudents)
          // context.commit("setWeekPlanCourses", apiResult)
        })
        .catch(error => console.log(error))
    },
    fetchGroupsForWeekPlan(context, weekPlanId){
      Weektaak.getGroupsForWeekPlan(weekPlanId)
        .then(apiResult => {
          // context.commit("setWeekPlanCourses", apiResult)
        })
        .catch(error => console.log(error))
    },
    setDataImport(context, dataimport){
      context.commit("setDataImport", dataimport)
    },
    fetchWeekPlanCourses(context, id){
      // console.log("Fetching week plan courses ")
      // console.log(id)
      Weektaak.getWeekPlanCourses(id.id)
        .then(apiResult => {
          context.commit("setWeekPlanCourses", apiResult)
        })
        .catch(error => console.log(error))
    },
    addCourse(context, keyword) {
      Weektaak.createCourse(keyword)
        .then(apiResult => {
          // console.log("results :")
          // console.log(apiResult.courses)
          context.commit("setCourses", apiResult.courses)
        })
        .catch(error => console.log(error))
        .finally(() => {
          // done
        })
    },
    getExtraTasks(context, id){
      Weektaak.getExtraTasks(id)
        .then(apiResult => {
          // console.log("results :")
          // console.log(apiResult)
          context.commit("setExtraTasks", apiResult)
        })
        .catch(error => console.log(error))
        .finally(() => {
          // done
        })
    },
    startDraggingNewLesson(context){
      context.commit('setNewLessonDragging', true)
    },
    stopDraggingNewLesson(context){
      context.commit('setNewLessonDragging', false)
    },
    showTrash(context) {
      context.commit('setWeekPlanShowTrash', true)
    },
    hideTrash(context) {
      context.commit('setWeekPlanShowTrash', false)
    },
    setDefaultDays (context, days) {
      context.commit('setDefaultDays', days)
    },
    setSeedDataWeekPlan (context) {
      // context.commit('setLoadingState', true)
      context.commit("saveSeedDataWeekPlan", seedDataWeekPlan)
      
    },
    addPictoToTrash (context, params){
      // console.log("deleting")
      console.log(params)
      Weektaak.deletePlanPicto(params.id, params.weekPlanId)
        .then(apiResult => {
          // console.log("trashed lesson...")
          
        })
        .catch(error => console.log(error))
        .finally(() => {
          // context.commit('setLoadingState', false)
        })
    },
    addToTrash (context, params){
      Weektaak.deleteLesson(params.id, params.weekPlanId)
        .then(apiResult => {
          // console.log("trashed lesson...")
          
        })
        .catch(error => console.log(error))
        .finally(() => {
          // context.commit('setLoadingState', false)
        })
    },  
    fetchPrintJobs (context, params){
      Weektaak.getPrintJobs(params.id)
        .then(apiResult => {
          context.commit("setPrintJobs", apiResult.print_jobs)
          
        })
        .catch(error => console.log(error))
        .finally(() => {
          // context.commit('setLoadingState', false)
        })
    },
    fetchBook (context, id){
      Weektaak.fetchBook(id)
      .then((result) => {
        context.commit("setBook", result)
      }).catch((error) => {
        console.log(error)
      })
    },
    addBookBlock(context){
      Weektaak.addBookBlock(context.state.book.id)
      .then((result) => {
        context.commit("setBook", result)
      })
    },
    saveExtraTask(context, params){
      Weektaak.addExtraTask(params.week_plan_id, params.course_id)
      .then(apiResult => {
          // context.commit("setCourses", apiResult.courses)
          context.commit("setExtraTasks", apiResult)
        })
        .catch(error => console.log(error))
        .finally(() => {
          // context.commit('setLoadingState', false)
        })
    },
    fetchCourses (context, params){
      Weektaak.getCourses(params.id)
        .then(apiResult => {
          context.commit("setCourses", apiResult.courses)
          
        })
        .catch(error => console.log(error))
        .finally(() => {
          // context.commit('setLoadingState', false)
        })
    },
    fetchLevels (context, params){
      Weektaak.getLevels(params.id)
        .then(apiResult => {
          context.commit("setLevels", apiResult.levels)
          
        })
        .catch(error => console.log(error))
        .finally(() => {
          // context.commit('setLoadingState', false)
        })
    },
    fetchStudents (context, params){
      Weektaak.getStudents(params.id)
        .then(apiResult => {
          // console.log("Setting students!")
          // console.log(apiResult.students)
          context.commit("setStudents", apiResult.students)
          
        })
        .catch(error => console.log(error))
        .finally(() => {
          // context.commit('setLoadingState', false)
        })
    },
    setModalLessonId (context, id) {
      context.commit("modalLessonId", id)
    },
    setSchoolCourses (context, courses){
      context.commit("schoolCourses", courses)
    },
    setCourseToSchoolCourses( context, course){
      context.commit("appendCourseToCourses", course)
    },
    fetchPictoWeekPlan (context, id){
      Weektaak.getPictoWeekPlan(id)
        .then(apiResult => {
          console.log("setting pwp")
          context.commit("setPictoWeekPlan", apiResult)
          
        })
        .catch(error => {
          console.log("foutje...")
          alert("Er ging iets fout. De beheerders van Weektaak.com hebben hiervan bericht ontvangen. Probeer het later nog eens.")
          console.log(error)
        })
        .finally(() => {
        })
    },
    fetchWeekPlanPerCourse (context, options) {
      Weektaak.getWeekPlanPerCourse(options.id, options.clockless)
        .then(apiResult => {
          // context.commit('setLoadingState', false)
          context.commit("setWeekPlanPerCourse", apiResult)
          
        })
        .catch(error => {
          console.log("foutje...")
          alert("Er ging iets fout. De beheerders van Weektaak.com hebben hiervan bericht ontvangen. Probeer het later nog eens.")
          console.log(error)
        })
        .finally(() => {
          // context.commit('setLoadingState', false)
        })
    },
    fetchWeekPlan (context, id) {
      Weektaak.getWeekPlanDays(id.id)
        .then(apiResult => {
          // context.commit('setLoadingState', false)
          context.commit("setWeekPlanDays", apiResult)
          
        })
        .catch(error => {
          console.log("foutje...")
          alert("Er ging iets fout. De beheerders van Weektaak.com hebben hiervan bericht ontvangen. Probeer het later nog eens.")
          console.log(error)
        })
        .finally(() => {
          // context.commit('setLoadingState', false)
        })
    },
    setStudentWeekPlans (context, payload){
      context.commit("setStudentWeekPlans", payload)
    }

  },

  mutations: {
    setUserChat(state, payload){
      state.user_chat = payload
    },
    setChatCounter(state, payload) {
      console.log('updating chat counter')

    },
    setChats(state, payload){
      console.log("setting chats")
      state.unreadChats = payload.unread_messages
      state.chats = payload.chats
    },
    setWeekPlanSettings(state, object){
      state.weekPlanSettings = object
    },
    setStudentWeekPlans(state, payload){
      console.log("setting new payload")
      // console.log(payload)
      state.studentWeekPlans = payload
    },
    showConfetti(state, payload){
      console.log("showing confetti")
      // this.$confetti.start();
      state.throwConfetti = true
      setTimeout(function () {
        state.throwConfetti = false
        // this.$confetti.stop();
      }.bind(this), payload.duration)
    },
    setDigitalWeekPlanCredit( state, payload){
      state.digitalWeekPlan.use_credits     = payload.use_credits
      state.digitalWeekPlan.credit_goal     = payload.credit_goal
      state.digitalWeekPlan.student_credits = payload.student_credits
      state.digitalWeekPlan.group_credits   = payload.group_credits
    },
    setDigitalWeekPlan( state, weekPlan){
      state.digitalWeekPlan = weekPlan
    },
    setDigitalWeekPlanMessages( state, weekPlanMessages){
      state.digitalWeekPlanMessages = weekPlanMessages
    },
    addDigitalWeekPlanMessage( state, message ) {
      const obj = {'body': message, 'sender': 'student', 'read_by_student': true, 'created_at': "Zojuist" }
      state.digitalWeekPlanMessages = { ...state.digitalWeekPlanMessages, obj}
    },
    setPreviousDigitalWeekPlanMessages(state, weekPlanMessages){
      state.previousDigitalWeekPlanMessages = weekPlanMessages
    },
    setDataImport( state, dataimport){
      state.importJob = dataimport
    },
    modalLessonId (state, id){
      state.modalLessonId = id
    },
    setWeekPlanShowTrash (state, value) {
      state.showWeekPlanTrash = value
    },
    setNewLessonDragging (state, value) {
      state.draggingNewLesson = value
    },
    setLoadingState (state, loadingState) {
      // Vue.set(state.isLoading, loadingState)

      state.loading = loadingState

      // {loading: loadingState}
      // console.log("updating loading")
      // console.log(state.isLoading)
    },
    saveSeedDataWeekPlan (state, data){
      state.weekPlan = data
    },
    setWeekPlanPerCourse (state, apiResult) {
      // console.log("updating wp per course")
      // state.weekPlanPerCourse = {courses: [], loadingLessonIds: []}
      state.weekPlanPerCourse = apiResult
      // console.log("updated wp per course:")
      // console.log(apiResult)
    },
    setWeekPlanDays (state, apiResult) {
      // console.log("setting wpd")
      // console.log(apiResult)
      state.weekPlan = apiResult
    },
    setWeekPlanCourses (state, apiResult){
      state.weekPlanCourses = apiResult.courses
    },
    setCourses (state, apiResult) {
      // console.log('result:')
      // console.log(apiResult)
      if (apiResult.courses != undefined){
        state.courses = apiResult.courses
      } else {
        state.courses = apiResult  
      }
      
    },
    setLevels (state, apiResult) {
      // console.log("setting levels")
      // console.log(apiResult)
      state.levels = apiResult
    },
    setStudents (state, apiResult) {
      // console.log("setting students!")
      // console.log(apiResult)
      if (apiResult.students != undefined){
        state.students = apiResult.students
      } else {
        state.students = apiResult
      }
    },
    setPrintJobs (state, apiResult) {
      if (apiResult.print_jobs != undefined){
        state.printJobs = apiResult.print_jobs
      } else {
        state.printJobs = apiResult
      }
    },
    setDefaultDays (state, days) {
      state.weekPlan.weekPlanDays = days
    },
    setExtraTasks (state, apiResult){
      state.extraTasks = apiResult
    },
    setBook (state, apiResult) {
      // console.log("Setting book")
      // console.log(apiResult)
      state.book = apiResult
    },
    saveGroupsToShow (state, groupsToShow) {
      state.groupsToShow = groupsToShow
    },
    schoolCourses (state, courses){
      state.schoolCourses = courses
    },
    appendCourseToCourses (state, course){
      state.schoolCourses.push(course)
    },
    setPictoWeekPlan (state, data){
      console.log("setting picto week plan")
      state.pictoPlan = data
    }
  }

})
